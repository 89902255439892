import { AetherEventConfiguration } from './aether-environment';
import { LabGuide } from './lab-guide';
import { ListParamsStrict } from './list-params';
import {
  Access,
  Asset,
  AssetInstructorType,
  AssetListScope,
  AssetSkillLevel,
  AssetType,
  BasicAsset,
  BasicAssetActivity,
  BasicTag,
  Environment,
  EnvironmentComponent,
  InternalAssetCreateRequestBody,
  Reminders,
  ResourceIdObject,
  ResourceList,
  StrictBasicUser,
  SupportedLanguage,
} from './shared';
import { BasicSurvey } from './surveys';
import { TenantConfiguration } from './tenant';
import { Values } from './type-utils';

export const REGISTRATION_RETRY_LIMIT = 5;

export interface BaseTrainingEvent {
  access: Access;
  coverImage: string | null;
  createdAt: string;
  description: string | null;
  duration: number;
  endTime: string;
  id: number;
  instructors: TrainingEventInstructor[];
  language: string | SupportedLanguage;
  location: string | null;
  name: string;
  notes: string | null;
  objectives: string | null;
  openSeats: number;
  prerequisites: string | null;
  registrationCost: number;
  registrationCostInPoints: number;
  seatLimit: number;
  sessions: TrainingEventSession[];
  skillLevel: AssetSkillLevel;
  startTime: string;
  tags: BasicTag[];
  thumbnail: string | null;
  timezone: string;
  timezoneOffset: string;
  type: AssetType.Session;
  updatedAt: string;
  virtual: boolean;
  version: string | null;
}

export interface TrainingEvent extends BaseTrainingEvent {
  activity: BasicAssetActivity;
  labGuide: LabGuide | null;
  meeting: Meeting | null;
  parents: Asset[] | null;
  registration?: { id: number } | null;
  resources: Resource[] | null;
  surveys: BasicSurvey[] | null;
}

export interface InternalTrainingEvent extends BaseTrainingEvent {
  active: boolean;
  aetherConfig: AetherEventConfiguration | null;
  provisioningOffset: number | null;
  deletedAt: string | null;
  environment: EnvironmentConfiguration | null;
  label: string | null;
  labGuide: BasicAsset | null;
  meeting: Meeting | null;
  owner: StrictBasicUser | null;
  parents: BasicAsset[];
  reminders: Reminders | null;
  resources: Resource[];
  surveys: BasicAsset[];
  tenantConfig?: TenantConfiguration | null;
  version: string | null;
}

export interface TrainingEventInstructor {
  type: AssetInstructorType;
  user: StrictBasicUser;
}

export interface TrainingEventSession {
  agenda: AgendaItem[];
  startTime: string;
  endTime: string;
  breakTimeInHours?: number;
}

export interface AgendaItem {
  label: string | null;
  title: string | null;
}

export interface Meeting {
  type: MeetingType;
  url: string;
}

export interface Resource {
  id: string;
  label: string;
  url: string;
  type?: ResourceType;
  instructorsOnly?: boolean;
}

export enum ResourceType {
  File = 'file',
  Url = 'url',
}

export const MeetingType = {
  MsTeams: 'ms-teams',
  WebEx: 'webex',
  Zoom: 'zoom',
} as const;
export type MeetingType = Values<typeof MeetingType>;

export type EnvironmentConfiguration = Required<
  Pick<
    Environment,
    'costCenter' | 'provider' | 'provisionDynatraceEnvironment' | 'region' | 'template' | 'templateOptions' | 'tenantConfig'
  >
> &
  Pick<Environment, 'components'>;

export type BaseBasicTrainingEvent = Pick<
  BaseTrainingEvent,
  | 'access'
  | 'createdAt'
  | 'duration'
  | 'endTime'
  | 'id'
  | 'language'
  | 'location'
  | 'name'
  | 'openSeats'
  | 'seatLimit'
  | 'startTime'
  | 'thumbnail'
  | 'timezone'
  | 'timezoneOffset'
  | 'type'
  | 'updatedAt'
  | 'virtual'
  | 'version'
>;

export interface BasicTrainingEvent extends BaseBasicTrainingEvent {
  registration: TrainingEvent['registration'];
}

export interface InternalBasicTrainingEvent extends BaseBasicTrainingEvent {
  active: InternalTrainingEvent['active'];
  deletedAt: InternalTrainingEvent['deletedAt'];
  owner: InternalTrainingEvent['owner'];
  instructors: InternalTrainingEvent['instructors'];
}

export interface InternalListTrainingEventsParams extends Omit<ListParamsStrict, 'all' | 'archive'> {
  active?: boolean;
  endTime?: string;
  id?: number[];
  name?: string;
  ownerEmail?: string;
  scope?: AssetListScope;
  startTime?: string;
}

export type InternalTrainingEventList = ResourceList<Partial<InternalBasicTrainingEvent>>;

export interface CreateTrainingEventRequestBody
  extends Pick<
    InternalAssetCreateRequestBody,
    | 'access'
    | 'active'
    | 'description'
    | 'instructors'
    | 'label'
    | 'language'
    | 'name'
    | 'objectives'
    | 'prerequisites'
    | 'skillLevel'
    | 'surveys'
    | 'tags'
  > {
  aetherConfig?: InternalTrainingEvent['aetherConfig'];
  provisioningOffset?: InternalTrainingEvent['provisioningOffset'];
  environment?: InternalTrainingEvent['environment'];
  labGuide?: ResourceIdObject | null;
  location?: InternalTrainingEvent['location'];
  meeting?: InternalTrainingEvent['meeting'];
  notes?: InternalTrainingEvent['notes'];
  parents?: ResourceIdObject[];
  registrationCost: InternalTrainingEvent['registrationCost'];
  registrationCostInPoints: InternalTrainingEvent['registrationCostInPoints'];
  reminders?: InternalTrainingEvent['reminders'];
  resources?: InternalTrainingEvent['resources'];
  seatLimit: InternalTrainingEvent['seatLimit'];
  sessions: InternalTrainingEvent['sessions'];
  tenantConfig?: InternalTrainingEvent['tenantConfig'];
  timezone: InternalTrainingEvent['timezone'];
  timezoneOffset: InternalTrainingEvent['timezoneOffset'];
  virtual: InternalTrainingEvent['virtual'];
  version?: InternalTrainingEvent['version'];
}

export interface UpdateTrainingEventRequestBody extends Partial<CreateTrainingEventRequestBody> {
  coverImage?: string | null;
  deletedAt?: null;
  thumbnail?: string | null;
}

export type RescheduleTrainingEventRequestBody = Pick<UpdateTrainingEventRequestBody, 'sessions' | 'timezone' | 'timezoneOffset'> & {
  startTime?: string;
};

export interface TrainingEventAttendee {
  environment: {
    id: number;
    status: Exclude<Environment['status'], undefined>;
    tenantId: Exclude<Environment['tenantId'], undefined>;
    components: (EnvironmentComponent & { isDynatraceSaas: boolean })[];
  } | null;
  registration: {
    id: number;
    invited: boolean;
    reachedMaxInvitationRetries: boolean;
    altEmail?: string;
  };
  user: StrictBasicUser;
}

export enum UpdateType {
  None = 'none',
  Create = 'create',
  Update = 'update',
  Delete = 'delete',
}

export enum SessionUpdateType {
  Reschedule,
  Recreate,
}

export interface InstructorUpdate {
  id: number;
  type: UpdateType.Create | UpdateType.Delete;
}
