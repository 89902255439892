import { Values } from './type-utils';

export interface CreateEvaluationRequestBody {
  testAttemptId: number;
}

export const AutomaticallySetFinalGrade = {
  All: 'all',
  PassingOnly: 'passingOnly',
  None: 'none',
} as const;
export type AutomaticallySetFinalGrade = Values<typeof AutomaticallySetFinalGrade>;
