import { Values } from './type-utils';

export const TokenServices = {
  AccountManagementAutoGraderProd: 'accountManagementAutoGraderProd',
  AccountManagementAutomationStg: 'accountManagementAutomationStg',
  AccountManagementAutomationLabs1Prod: 'accountManagementAutomationProd',
  AccountManagementAutomationLabs2Prod: 'accountManagementAutomationLabs2Prod',
  AccountManagementAutomationCertificationsProd: 'accountManagementAutomationCertificationsProd',
  AccountManagementAutomationPartnerProd: 'accountManagementAutomationPartnerProd',
  AccountManagementMonitoringProd: 'accountManagementMonitoringProd',
  AdaAutomationStg: 'adaAutomationStg',
  AdaAutomationProd: 'adaAutomationProd',
};
export type TokenServices = Values<typeof TokenServices>;

export enum TokenStage {
  Development = 'dev',
  Staging = 'stg',
  Production = 'prod',
}

export interface TokenCredentials {
  stage: TokenStage;
  clientId: string;
  clientSecret: string;
  scopes?: string;
  resource?: string;
}

export interface TokenInfoList {
  [service: TokenServices]: TokenInfo | null;
}

export interface TokenInfo {
  token: string;
  expiresAt: string | Date;
  expiresIn?: number;
}

export interface TokenInfoWithExpiration extends TokenInfo {
  expiresIn: number;
}
