import { EnvironmentModuleSummary } from '@aether/types';
import { ListParamsStrict, ListResourceScope, ParamValueCondition } from './list-params';
import { Registration } from './registration';
import { Asset, Environment, EnvironmentComponent, EnvironmentStatus, ResourceList, User } from './shared';
import { Values } from './type-utils';

export class UpdateAetherEnvironmentClass {
  startTime?: string | Date;
  endTime?: string | Date;
  timezone?: string;
  eventId?: Asset['id'];
  initialized?: boolean;
  templateId?: string;
  templateVersionId?: string;
  name?: string;
  costCenter?: number;
  aetherId?: string;
  aetherProvisioningStatus?: AetherProvisioningStatus;
  aetherHealthStatus?: AetherHealthStatus;
  aetherResources?: AetherEnvironmentResource[];
  deletedAt?: string | Date | null;
}

export class CreateAetherEnvironmentClass {
  startTime: string | Date;
  endTime: string | Date;
  timezone: string;
  eventId: Asset['id'];
  templateId: string;
  templateVersionId: string;
  name: string;
  costCenter?: number;
}

export class AetherEnvironmentClass extends CreateAetherEnvironmentClass {
  readonly id: number;
  readonly createdAt: string | Date;
  updatedAt: string | Date;

  initialized: boolean;

  aetherId?: string;
  aetherProvisioningStatus?: AetherProvisioningStatus;
  aetherHealthStatus?: AetherHealthStatus;
  aetherResources?: AetherEnvironmentResource[];
  deletedAt?: string | Date | null;

  event?: Asset;
  registrations?: Registration[];
}

export interface UpdateAetherEnvironment extends UpdateAetherEnvironmentClass {}
export interface CreateAetherEnvironment extends CreateAetherEnvironmentClass {}
export interface AetherEnvironment extends AetherEnvironmentClass {}

export const AetherProvisioningStatus = {
  Deprovisioning: 'DEPROVISIONING',
  Failed: 'FAILED',
  Provisioning: 'PROVISIONING',
  Running: 'RUNNING',
  Scheduled: 'SCHEDULED',
  Stopped: 'STOPPED',
  DeletedEarly: 'DELETED_EARLY',
} as const;
export type AetherProvisioningStatus = Values<typeof AetherProvisioningStatus>;

export const AetherHealthStatus = {
  Healthy: 'HEALTHY',
  Pending: 'PENDING',
  Unhealthy: 'UNHEALTHY',
  None: 'NONE',
} as const;
export type AetherHealthStatus = Values<typeof AetherHealthStatus>;

export type AetherEnvironmentResource = Pick<EnvironmentModuleSummary, 'id' | 'name' | 'type' | 'region' | 'status' | 'provisioning'> & {
  accountUuid?: string;
};

export type AetherEnvironmentList = ResourceList<AetherEnvironment>;

export interface InternalAetherEnvironmentListParams extends ListParamsStrict {
  id?: number[];
  startTime?: ParamValueCondition;
  endTime?: ParamValueCondition;
  timezone?: string;
  eventId?: Asset['id'];
  event?: boolean;
  name?: string;
  initialized?: boolean;
  aetherId?: string;
  provisioningStatus?: AetherProvisioningStatus;
  provisioningNotStatus?: string;
  healthStatus?: AetherHealthStatus;
  scope?: ListResourceScope;
  registrations?: boolean;
}

export const AetherEnvironmentUseCase = {
  Individual: 'individual',
  Group: 'group',
} as const;
export type AetherEnvironmentUseCase = Values<typeof AetherEnvironmentUseCase>;

export type AetherEventConfiguration = Pick<
  AetherEnvironment,
  'startTime' | 'endTime' | 'timezone' | 'templateId' | 'templateVersionId' | 'name'
> & {
  environmentUseCase: AetherEnvironmentUseCase;
  costCenter?: number;
};

export type AetherExamConfiguration = Pick<AetherEnvironment, 'templateId' | 'templateVersionId' | 'name'> & {
  environmentUseCase: AetherEnvironmentUseCase;
  costCenter?: number;
};

export interface AetherSecretConfig {
  apiUrl: string;
  jwtToken: string;
}

/**
 * For each raw environment resource summary direct from Aether, convert it to the
 *   type that gets stored in the database, AetherEnvironmentResource
 * @param moduleSummaries - The raw environment resource summaries that need to be converted
 * @returns A list of converted AetherEnvironmentResource objects
 */
export function convertEnvironmentModuleSummariesToAetherEnvironmentResources(
  moduleSummaries: EnvironmentModuleSummary[],
): AetherEnvironmentResource[] {
  return moduleSummaries.map((moduleSummary) => {
    return {
      id: moduleSummary.id,
      name: moduleSummary.name,
      type: moduleSummary.type,
      region: moduleSummary.region,
      status: moduleSummary.status,
      provisioning: moduleSummary.provisioning,
      accountUuid: moduleSummary?.account?.externalId,
    };
  });
}

enum AetherEnvironmentModuleProvisioningStatus {
  Cancelled = 'CANCELLED',
  Failed = 'FAILED',
  InProgress = 'IN_PROGRESS',
  Scheduled = 'SCHEDULED',
  Success = 'SUCCESS',
}

const AetherModuleProvisioningStatusToDtuEnvironmentStatus = {
  [AetherEnvironmentModuleProvisioningStatus.Cancelled]: EnvironmentStatus.Cancelled,
  [AetherEnvironmentModuleProvisioningStatus.Failed]: EnvironmentStatus.Pending,
  [AetherEnvironmentModuleProvisioningStatus.InProgress]: EnvironmentStatus.Starting,
  [AetherEnvironmentModuleProvisioningStatus.Scheduled]: EnvironmentStatus.Future,
  [AetherEnvironmentModuleProvisioningStatus.Success]: EnvironmentStatus.Running,
};

const AetherEnvironmentStatusToDtuEnvironmentStatus = {
  [AetherProvisioningStatus.Deprovisioning]: EnvironmentStatus.Stopped,
  [AetherProvisioningStatus.Failed]: EnvironmentStatus.Pending,
  [AetherProvisioningStatus.Provisioning]: EnvironmentStatus.Starting,
  [AetherProvisioningStatus.Running]: EnvironmentStatus.Running,
  [AetherProvisioningStatus.Scheduled]: EnvironmentStatus.Future,
  [AetherProvisioningStatus.Stopped]: EnvironmentStatus.Stopped,
  [AetherProvisioningStatus.DeletedEarly]: EnvironmentStatus.Stopped,
};

/**
 * Converts an Aether environment's properties to a DTU environment object
 * @param aetherEnvironment - Aether environment to be converted
 * @param user - User who is assigned to the Aether environment
 * @returns - DTU Environment
 * @returns - null if Aether environment is undefined
 */
export function convertAetherEnvironmentToDtuEnvironment(aetherEnvironment: AetherEnvironment, user: User): Environment | null {
  if (!aetherEnvironment) {
    return null;
  }

  const environment: Environment = {
    id: aetherEnvironment.id,
    name: aetherEnvironment.name,
    status: AetherEnvironmentStatusToDtuEnvironmentStatus[aetherEnvironment.aetherProvisioningStatus ?? AetherProvisioningStatus.Scheduled],
    tenantId: 1,
  };

  const components: EnvironmentComponent[] =
    aetherEnvironment.aetherResources?.map((resource) => {
      const isDynatraceComponent = !!resource.provisioning?.output?.environmentAppUrl;
      const isLinuxComponent = !!resource.provisioning?.output?.interfaceUrl;

      return {
        id: isDynatraceComponent
          ? resource.provisioning?.output?.environmentId
          : isLinuxComponent
          ? resource.provisioning?.output?.instanceId
          : resource.id,
        type: isDynatraceComponent ? 'dynatrace' : isLinuxComponent ? 'linux' : undefined,
        name: resource.name,
        status: AetherModuleProvisioningStatusToDtuEnvironmentStatus[resource.status],
        access: {
          type: isDynatraceComponent ? 'web' : undefined,
          url: resource.provisioning?.output?.environmentAppUrl ?? resource.provisioning?.output?.interfaceUrl ?? '',
          username: isDynatraceComponent ? user.email : isLinuxComponent ? resource.provisioning?.output?.instanceUser : '',
          password: resource.provisioning?.output?.instancePassword,
          token: resource.provisioning?.output?.accessToken,
          interface: isLinuxComponent,
        },
      };
    }) ?? [];

  components.sort((a) => (a.type === 'dynatrace' ? -1 : 1));
  return { ...environment, components };
}
