import { TenantStage } from './tenant';
import { TokenServices } from './token';
import { Values } from './type-utils';

export interface AccountManagementGroup {
  uuid: string;
  name: string;
  owner: string;
  description: string;
  hidden: boolean;
  createdAt: string | Date;
  updatedAt: string | Date;
}

export interface AccountManagementGroupList {
  count: number;
  items: AccountManagementGroup[];
}

export interface AccountManagementPolicy {
  uuid: string;
  name: string;
  description?: string;
  tags?: string[];
  statementQuery?: string;
}

export interface AccountManagementAccountResource {
  tokenService: TokenServices;
  stage: TenantStage;
  instructorGroupUuid: string;
  participantPolicyUuid: string;
}

export const AccountManagementAdminIds: number[] = [
  1039718, 7364056, 7305701, 6057183, 7322348, 15384, 7344670, 7380272, 7328481, 2794326, 20318, 3073584, 28432,
];

export const AccountManagementAccountUuids = {
  Labs1: '65458478-ab94-4669-b798-108436f8d65e',
  Labs2: 'e512214f-25c5-456a-a53c-d3addde4ed09',
  Certifications: 'a562b172-0b1a-447d-a025-3e216e9cd7a5',
  Partner: '562d75aa-9e4c-405d-93cf-17c779826467',
  Hardening: 'f02d2eb1-d9c3-4aa4-811f-87bf0f617f73',
};
export type AccountManagementAccountUuids = Values<typeof AccountManagementAccountUuids>;

export const AccountManagementAccountUuidToResource: { [accountUuid: string]: AccountManagementAccountResource } = {
  [AccountManagementAccountUuids.Labs1]: {
    tokenService: TokenServices.AccountManagementAutomationLabs1Prod,
    stage: TenantStage.Production,
    instructorGroupUuid: 'd8df2661-1bf8-427d-ba2f-013f1ea7a934',
    participantPolicyUuid: '6eaa9fdb-1219-4ce7-b512-d0aafa2baf92',
  },
  [AccountManagementAccountUuids.Hardening]: {
    tokenService: TokenServices.AccountManagementAutomationStg,
    stage: TenantStage.Hardening,
    instructorGroupUuid: '4cd70b21-9e68-46c1-b14b-fa700c26202d',
    participantPolicyUuid: 'c63d3dee-761e-4ea4-a6a1-e5febe12a6c7',
  },
  [AccountManagementAccountUuids.Labs2]: {
    tokenService: TokenServices.AccountManagementAutomationLabs2Prod,
    stage: TenantStage.Production,
    instructorGroupUuid: '58b01d11-3b32-4249-89f6-f3b51d713a9a',
    participantPolicyUuid: '45d79409-a2c9-4865-b02d-d754f312030e',
  },
  [AccountManagementAccountUuids.Certifications]: {
    tokenService: TokenServices.AccountManagementAutomationCertificationsProd,
    stage: TenantStage.Production,
    instructorGroupUuid: 'aa9d1d45-f67b-4ea5-a63c-a369808dd250',
    participantPolicyUuid: 'a59fd467-79c1-4bb5-a351-dba8b3d5eb8c',
  },
  [AccountManagementAccountUuids.Partner]: {
    tokenService: TokenServices.AccountManagementAutomationPartnerProd,
    stage: TenantStage.Production,
    instructorGroupUuid: '99e254fd-a2a9-4ae8-8d49-74152c183c05',
    participantPolicyUuid: '37c335b5-cfd1-4b24-a970-44f19191029e',
  },
};
